<template>
  <b-card>
    <h1>Bonus Summary</h1>
    <vue-apex-charts :options="options" :series="series" type="line" />
    <b-button @click="openModal" variant="primary" class="mt-3">View Details</b-button>

    <!-- Modal para mostrar detalles -->
    <b-modal v-model="isModalVisible" size="lg" title="Appointments Details" hide-footer>
      <!-- Selector de fecha con búsqueda -->
      <b-form-group label="Select Date">
        <b-form-select
          v-model="selectedDate"
          :options="dateOptions"
          @change="loadAppointments">
          <template #first>
            <b-form-input
              v-model="dateSearchQuery"
              placeholder="Search date..."
              @input="filterDates"></b-form-input>
          </template>
        </b-form-select>
      </b-form-group>

      <!-- Filtros por identificación o nombre -->
      <b-form-group label="Filter by Identification or Name">
        <b-form-input v-model="searchQuery" placeholder="Enter ID or Name"></b-form-input>
      </b-form-group>

      <!-- Tabla de detalles -->
      <b-table
        striped
        hover
        :items="filteredAppointments"
        :fields="tableFields"
        :per-page="perPage"
        :current-page="currentPage"
        :empty-text="tableEmptyText">
        <template #cell(user)="data">
          {{ data.item.user.name }} {{ data.item.user.lastname }}
        </template>
        <template #cell(total)="data">
          {{ dollars.format(data.item.total) }}
        </template>
      </b-table>

      <!-- Paginación -->
      <b-pagination
        v-model="currentPage"
        :total-rows="filteredAppointments.length"
        :per-page="perPage"
        aria-controls="appointments-table"></b-pagination>

      <!-- Totales -->
      <div class="mt-3">
        <!-- <strong>Total Base Bonus:</strong> {{ dollars.format(totalBaseBonus) }}<br />
        <strong>Total Extra Bonus:</strong> {{ dollars.format(totalExtraBonus) }}<br /> -->
        <strong>Grand Total:</strong> {{ dollars.format(grandTotal) }}
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import mixn from '@/components/mixn.vue'
import {
  BCard,
  BModal,
  BTable,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BModal,
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  props: {
    bonus: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: true,
          },
        },
        noData: {
          text: 'No data available',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
          },
        },
        stroke: {
          curve: 'smooth',
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            rotate: -45,
          },
        },
        yaxis: {
          title: {
            text: 'Amount',
          },
        },
        colors: ['#008FFB', '#00E396'],
      },
      isModalVisible: false,
      selectedDate: null,
      dateSearchQuery: '',
      searchQuery: '',
      perPage: 10,
      currentPage: 1,
      tableFields: [
        { key: 'user', label: 'User' },
        { key: 'base_bonus', label: 'Base Bonus' },
        { key: 'extra_bonus', label: 'Extra Bonus' },
        { key: 'total', label: 'Total' },
      ],
      dollars: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      }),
    }
  },
  computed: {
    // Obtener las fechas disponibles
    dateOptions() {
      return Object.keys(this.bonus).filter(date =>
        date.toLowerCase().includes(this.dateSearchQuery.toLowerCase())
      )
    },
    // Filtrar appointments por identificación o nombre
    filteredAppointments() {
      if (!this.selectedDate || !this.bonus[this.selectedDate]) return []
      const appointments = this.bonus[this.selectedDate][0].appointments
      console.log('🚀 ~ filteredAppointments ~ appointments:', appointments)

      return appointments.filter(appointment => {
        const user = appointment.user
        const identificationString = user.identification
          ? user.identification.toString()
          : '' // Convertir a cadena
        return (
          identificationString.includes(this.searchQuery) || // Ahora es válido
          user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.lastname.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      })
    },
    // Calcular totales
    totalBaseBonus() {
      return this.filteredAppointments.reduce((sum, item) => sum + item.base_bonus, 0)
    },
    totalExtraBonus() {
      return this.filteredAppointments.reduce((sum, item) => sum + item.extra_bonus, 0)
    },
    grandTotal() {
      return this.filteredAppointments.reduce((sum, item) => sum + item.total, 0)
    },
    // Mensaje cuando no hay datos
    tableEmptyText() {
      return this.selectedDate && this.filteredAppointments.length === 0
        ? 'No appointments found for the selected date.'
        : 'Select a date to view appointments.'
    },
  },
  methods: {
    openModal() {
      this.isModalVisible = true
    },
    loadAppointments() {
      // Reiniciar la paginación al cambiar de fecha
      this.currentPage = 1
    },
    filterDates() {
      // Actualizar las opciones de fecha al buscar
      this.selectedDate = null
    },
    setData(bonusData) {
      const dates = Object.keys(bonusData)
      const totalBonus = dates.map(date => bonusData[date][0].total_to_pay)
      const totalAppointments = dates.map(date => bonusData[date][0].total_appointments)

      this.series = [
        {
          name: 'Total Bonus',
          data: totalBonus,
        },
        {
          name: 'Total Appointments',
          data: totalAppointments,
        },
      ]

      this.options = {
        ...this.options,
        xaxis: {
          ...this.options.xaxis,
          categories: dates,
        },
      }
    },
  },
  mounted() {
    this.setData(this.bonus)
  },
}
</script>

<style scoped>
.apexcharts-tooltip {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
</style>
